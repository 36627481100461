import { isEmpty, pick } from 'lodash'
import { QUESTION_TYPE } from 'types'

/* eslint-disable no-unused-vars */
export default function filterQuestionnareResponses(responseValues, questions) {
  if (isEmpty(questions)) {
    throw new Error('Must provide questions for filtering')
  }

  const fieldsToKeep = removeUnnecessaryQuestionFields(
    responseValues,
    questions
  )

  return pick(responseValues, fieldsToKeep)
}

function removeUnnecessaryQuestionFields(responseValues, questions) {
  return questions.reduce((fields, { fieldName, type, required }) => {
    // Exclude photo questions.
    if (type === QUESTION_TYPE.PHOTO) {
      return fields
    }

    // Get the response value.
    const value = responseValues[fieldName]

    const isBlankString = typeof value === 'string' && value.trim() === ''

    // If the question is optional and the value is "blank",
    // skip including this field. Here we consider a blank value to be an empty string,
    // null, or undefined.
    if (
      required === false &&
      (isBlankString || value === null || value === undefined)
    ) {
      return fields
    }

    // Otherwise, include this field.
    fields.push(fieldName)
    return fields
  }, [])
}
